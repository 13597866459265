import { Component, OnInit } from "@angular/core";

@Component({
  selector: "hfc-contact-preferences-standalone",
  templateUrl: "./contact-preferences-standalone.component.html",
  styleUrls: ["./contact-preferences-standalone.component.scss"],
})
export class ContactPreferencesStandaloneComponent {
  public userEmail: string = "";
  public userPhoneNumber: string = "";
}
