<div class="checkbox-container">
  <label class="custom-checkbox-wrapper">
    <ng-container *ngIf="isFormGroup; else modelTemplate">
      <input
        type="checkbox"
        [formControl]="checkboxFormControl"
        (change)="onClicked()"
      />
    </ng-container>
    <ng-template #modelTemplate>
      <input
        type="checkbox"
        [ngModel]="model"
        (ngModelChange)="modelChange.emit($event)"
        [disabled]="isDisabled"
      />
    </ng-template>

    <span class="custom-checkbox"></span>
    <span class="checkbox-label" [class.disabled-label]="isDisabled">{{
      label
    }}</span>
  </label>
</div>
