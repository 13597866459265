import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "hf-custom-checkbox",
  templateUrl: "./custom-checkbox.component.html",
  styleUrls: ["./custom-checkbox.component.scss"],
})
export class CustomCheckboxComponent implements OnInit {
  @Input() public isFormGroup: boolean;
  @Input() public label: string;
  @Input() public checkboxFormControl: AbstractControl;
  @Input() public isDisabled?: boolean;
  @Input() public model?: boolean;

  @Output() public onCheckedChange: EventEmitter<boolean> = new EventEmitter();
  @Output() public modelChange = new EventEmitter<boolean>();

  ngOnInit(): void {
    if (this.isDisabled && this.checkboxFormControl instanceof FormControl) {
      this.checkboxFormControl.disable();
    }
  }

  get isChecked(): boolean {
    return this.checkboxFormControl?.value || false;
  }

  public onClicked(): void {
    if (this.checkboxFormControl instanceof FormControl) {
      const newValue = this.checkboxFormControl.value;

      this.checkboxFormControl.setValue(newValue);
      this.checkboxFormControl.markAsTouched();
      this.onCheckedChange.emit(newValue);
    }
  }
}
