<ng-container *ngIf="isPaperless !== undefined">
  <div class="paperless-wrapper">
    <svg width="24" height="24">
      <use [attr.xlink:href]="'assets/svg/sprite.svg#paperless'"></use>
    </svg>
    <span [@paperlessAnimation]="isPaperless ? 'paperless' : 'notPaperless'">{{
      getPaperlessGraphicText()
    }}</span>
  </div>
</ng-container>
