<header>
  <div class="heron-header-img-wrapper">
    <img
      src="assets/img/B_Corp_logo.svg"
      alt="B-Corp logo"
      role="img"
      aria-label="B-Corp logo"
      loading="lazy"
      class="bcorp_logo"
    /><img
      src="assets/img/Everglades-light-bg_fg.svg"
      alt="Everglades logo"
      role="img"
      aria-label="Everglades logo"
      loading="lazy"
    />
  </div>
</header>

<section class="content">
  <ng-content></ng-content>
</section>
