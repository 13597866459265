import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationService } from "../../services/navigation.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "hfc-confirmation",
  templateUrl: "./confirmation.component.html",
  styleUrls: ["./confirmation.component.scss"],
})
export class ConfirmationComponent implements OnInit {
  public resultTitle: string;
  public resultSubMessage: string;
  public imageHeight: string;
  public imageWidth: string;
  public status: "SUCCESS" | "ERROR";
  public isStandalone?: boolean;

  public statusClass: string;

  // open for more status icons (Material icons)
  private statusIcons = {
    SUCCESS: "check_circle",
    ERROR: "error_outline",
  };

  public get user() {
    return this.userService.user;
  }

  constructor(
    private router: Router,
    private userService: UserService,
    private navigationService: NavigationService
  ) {
    const extras = this.router.getCurrentNavigation().extras;
    console.log(extras);

    if (extras && extras.state) {
      this.resultTitle = extras.state["result-title"];
      this.resultSubMessage = extras.state["result-sub-message"];
      this.imageWidth = extras.state["result-img-width"];
      this.imageHeight = extras.state["result-img-height"];
      this.status = extras.state["result-status"];
      this.isStandalone = extras.state["isStandalone"];
    }
  }

  ngOnInit(): void {
    this.statusClass = this.status.toLowerCase();
  }

  getStatusIcon(): string {
    const status = this.status;
    this.statusClass = status.toLowerCase();
    return `assets/svg/sprites.svg#${this.statusIcons[status]}` || "";
  }

  public onReturnClicked() {
    this.navigationService.goToDashboard();
  }
}
