import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "privacyMask",
})
export class PrivacyMaskPipe implements PipeTransform {
  transform(value: string, type: "email" | "phone"): string {
    if (!value) return value;

    if (type === "email") {
      const [name, domain] = value.split("@");
      if (!domain || name.length < 3) return value;
      const visibleChars = 2;
      const maskedName =
        name.slice(0, visibleChars) + "*".repeat(name.length - visibleChars);
      return `${maskedName}@${domain}`;
    }

    if (type === "phone") {
      const countryCodeMatch = value.match(/^(\+\d{1,3})/);
      let countryCode = "";
      let numberWithoutCode = value;

      // If country code exists, extract it
      if (countryCodeMatch) {
        countryCode = countryCodeMatch[0];
        numberWithoutCode = value.replace(countryCode, "").trim();
      }

      const visibleDigits = 3;
      const maskedSection = numberWithoutCode
        .slice(0, numberWithoutCode.length - visibleDigits)
        .replace(/\d/g, "*");
      const visibleSection = numberWithoutCode.slice(-visibleDigits);

      if (countryCode) {
        return `${countryCode} ${maskedSection}${visibleSection}`;
      } else {
        return `${maskedSection}${visibleSection}`;
      }
    }

    return value;
  }
}
