import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NavigationService } from "../../../root/services/navigation.service";

@Component({
  selector: "hfc-contact-preference",
  templateUrl: "./contact-preference.component.html",
  styleUrls: ["./contact-preference.component.scss"],
})
export class ContactPreferenceComponent {
  @Input() isStandalone: boolean = true;
  @Input() userEmail: string;
  @Input() userPhoneNumber: string;

  // Output for 4-step
  @Output() public buttonClick = new EventEmitter<string>();

  public isPaperless: boolean;
  public importantMarketingState: any = {};
  public optionalMarketingState: any = {};

  constructor(private readonly navigationService: NavigationService) {}

  updateContactPreferences() {
    // If API POST successul, re-direct user to generic results component.
    // This work is coming on ticket EVER-496
    const extras = this.getResultExtras();

    this.navigationService.goToResultsComponent(
      extras.title,
      extras.message,
      extras.height,
      extras.width,
      extras.status,
      extras.url,
      true
    );
  }

  onImportantUpdatesChanged(changes: any) {
    this.importantMarketingState = {
      ...this.importantMarketingState,
      ...changes,
    };

    this.evaluatePaperlessStatus();
  }

  onOptionalUpdatesChanged(changes: any) {
    this.optionalMarketingState = {
      ...this.optionalMarketingState,
      ...changes,
    };
    this.evaluatePaperlessStatus();
  }

  private evaluatePaperlessStatus() {
    const post =
      this.importantMarketingState?.post ||
      this.optionalMarketingState?.postOptional;
    this.isPaperless = !post;
  }

  private getResultExtras() {
    return {
      title: "Preferences Updated.",
      message:
        " Please note you will continue to receive email and phone messaging for essential updates.",
      height: "3.75rem",
      width: "3.75rem",
      status: "SUCCESS" as const,
      url: "preferences-updated",
    };
  }
}
