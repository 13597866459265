import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, Input } from "@angular/core";

@Component({
  selector: "hfc-paperless-graphic",
  templateUrl: "./paperless-graphic.component.html",
  styleUrls: ["./paperless-graphic.component.scss"],
  animations: [
    trigger("paperlessAnimation", [
      state("paperless", style({ opacity: 1 })),
      state("notPaperless", style({ opacity: 1 })),
      transition("paperless <=> notPaperless", [
        style({ opacity: 0 }),
        animate("0.2s ease-in"),
      ]),
    ]),
  ],
})
export class PaperlessGraphicComponent {
  @Input() isPaperless!: boolean;
  constructor() {}

  getPaperlessGraphicText() {
    if (this.isPaperless) {
      return "Thank you for going paperless.";
    } else return "Please consider opting out of post to help the environment.";
  }
}
