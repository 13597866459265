import { SrcsetDirective } from "../directives/srcset.directive";
import { NgModule } from "@angular/core";
import { FormFieldDirective } from "./directives/form-field.directive";
import { BannerComponent } from "./banner/banner.component";
import { AttachmentImageComponent } from "./attachment-image/attachment-image.component";
import { NamedIconComponent } from "./named-icon/named-icon.component";
import { InjectSvgComponent } from "./inject-svg/inject-svg.component";
import { CommonModule } from "@angular/common";
import { AttachmentThumbImageComponent } from "./attachment-thumb-image/attachment-thumb-image.component";
import { WordingPipe } from "./pipes/wording.pipe";
import { CustomCurrencyPipe } from "./pipes/custom-currency.pipe";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CustomCheckboxComponent } from "./custom-checkbox/custom-checkbox.component";
import { PrivacyMaskPipe } from "./pipes/privacy-mask.pipe";

const exports = [
  SrcsetDirective,
  FormFieldDirective,
  BannerComponent,
  AttachmentImageComponent,
  AttachmentThumbImageComponent,
  NamedIconComponent,
  InjectSvgComponent,
  CustomCheckboxComponent,
  WordingPipe,
  CustomCurrencyPipe,
  PrivacyMaskPipe,
];

@NgModule({
  declarations: exports,
  exports: exports,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  providers: [WordingPipe, CustomCurrencyPipe],
})
export class EvergladesSharedMorule {}
