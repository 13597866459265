import { Component, OnInit } from "@angular/core";

@Component({
  selector: "hfc-standalone-form-container",
  templateUrl: "./standalone-form-container.component.html",
  styleUrls: ["./standalone-form-container.component.scss"],
})
export class StandaloneFormContainerComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
