import { OverlayModule } from "@angular/cdk/overlay";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxsModule } from "@ngxs/store";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
// import { NgxsStoragePluginModule } from "@ngxs/storage-plugin";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";

import { Environment } from "../../../../../src/app/website/services/environment.service";
import { environment } from "../../environments/environment";
import { DashboardModule } from "../dashboard/dashboard.module";
import { DocumentModule } from "../document/document.module";
import { ClientSharedModule } from "../shared/client-shared.module";
import { SharedModule } from "../shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./components/app/app.component";
import { LoginComponent } from "./components/login/login.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { SignupComponent } from "./components/signup/signup.component";
import { ThankYouComponent } from "./components/thank-you/thank-you.component";
import { ClientEnvironment } from "./services/client-environment.service";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { AppState } from "./state/app.state";
import { UnsubscribeComponent } from "./components/unsubscribe/unsubscribe.component";
import { UnsubscribeDoneComponent } from "./components/unsubscribe-done/unsubscribe-done.component";
import { UnsubscribeAbortComponent } from "./components/unsubscribe-abort/unsubscribe-abort.component";
import { ErrorComponent } from "./components/error/error.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    SignupComponent,
    ThankYouComponent,
    ErrorComponent,
    ForgotPasswordComponent,
    UnsubscribeComponent,
    UnsubscribeDoneComponent,
    UnsubscribeAbortComponent,
  ],
  imports: [
    NgxsModule.forRoot([AppState], {
      developmentMode: !environment.production,
    }),
    NgxsRouterPluginModule.forRoot(),
    // NgxsStoragePluginModule.forRoot({ key: "factfind.applicant" }),
    // NgxsLoggerPluginModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    DashboardModule,
    DocumentModule,
    SharedModule,
    ClientSharedModule,
    OverlayModule,
  ],
  providers: [{ provide: Environment, useClass: ClientEnvironment }],
  bootstrap: [AppComponent],
})
export class AppModule {}
