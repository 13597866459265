<header>
  <div class="heron-header-img-wrapper">
    <img
      src="assets/img/B_Corp_logo.svg"
      alt="B-Corp logo"
      role="img"
      aria-label="B-Corp logo"
      loading="lazy"
      class="bcorp_logo"
    /><img
      src="assets/img/Everglades-light-bg_fg.svg"
      alt="Everglades logo"
      role="img"
      aria-label="Everglades logo"
      loading="lazy"
    />
  </div>
</header>

<div class="container">
  <div class="result-container">
    <div class="result-container_information">
      <svg
        class="icon"
        [ngClass]="statusClass"
        [attr.width]="imageWidth"
        [attr.height]="imageHeight"
      >
        <use [attr.xlink:href]="getStatusIcon()"></use>
      </svg>

      <ng-container *ngIf="resultTitle">
        <h1 class="text-h1-medium-grey24">
          {{ resultTitle }}
        </h1>
      </ng-container>

      <ng-container *ngIf="resultSubMessage">
        <h4 class="message">
          {{ resultSubMessage }}
        </h4>
      </ng-container>
    </div>
  </div>
</div>

<footer>
  <ng-container *ngIf="!isStandalone"
    ><div class="buttons">
      <hfc-button type="primary" (click)="onReturnClicked()"
        >Return to Dashboard</hfc-button
      >
    </div>
  </ng-container>
</footer>
